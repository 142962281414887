import { APPS } from "../../constants/index.constants";

import {
  REDIRECT_DOCUMENT_VALIDATION_PATHS,
  REDIRECT_MAP_PATHS,
  ROUTES,
} from "../../constants/routes.constants";
import { WEBVIEW_GO_TO } from "../../constants/webview.constants";
import { useQuery } from "../../hooks/useQuery";
import {
  MESSAGE_TYPE,
  trackEventButtonToWebView,
} from "../../interfaces/webview.interfaces";
import { isWebview, postMessageToWebview } from "../../utils/webview";
import styles from "./styles.module.scss";

export interface SlugProps {
  title: string;
  subtitle: string;
  buttonName: string;
  image: string;
}

export function Slug({ title, subtitle, buttonName, image }: SlugProps) {
  const qs = useQuery();
  const app = qs.get("app");
  const currentPathname = window.location.pathname;

  const APP_ROUTES = {
    ecobici: ROUTES.ECOBICI,
    tembici: ROUTES.TEMBICI,
    bikeitau: ROUTES.BIKEITAU,
  };

  const redirectApp = (currentPathname: string) => {
    if (isWebview()) {
      const { ANTIFRAUD_WEBVIEW, MAP } = WEBVIEW_GO_TO;
      const goToParam = REDIRECT_DOCUMENT_VALIDATION_PATHS.includes(
        currentPathname
      )
        ? ANTIFRAUD_WEBVIEW
        : MAP;

      trackEventButtonToWebView(buttonName);

      postMessageToWebview({
        message_type: MESSAGE_TYPE.SUCCESS,
        data: {},
        go_to: goToParam,
      });
    } else {
      // TODO remove this redirect when the Braze campaign ends
      window.location.href = APP_ROUTES[app] || APP_ROUTES.bikeitau;
    }
  };

  const shouldShowButton = () => {
    const redirectPaths = REDIRECT_MAP_PATHS.concat(
      REDIRECT_DOCUMENT_VALIDATION_PATHS
    );
    return redirectPaths.includes(currentPathname);
  };

  // !important tomei a decisão de deixar as propriedades vindas do routes.tsx pois, caso ocorra algum problema, erro de lógica, ou qualquer outro fenômeno parecido, ele tem um valor default
  return (
    <div
      className={
        app === APPS.TEMBICI
          ? styles.homeContainerTembici
          : styles.homeContainer
      }
    >
      <img
        src={
          app === APPS.TEMBICI && image !== "assets/grayBike.svg"
            ? image.replace("Itau_e_Ecobici.svg", "Tembici.svg")
            : image
        }
        alt="Tembici"
      />
      <h2>{title}</h2>
      <h4>{subtitle}</h4>
      {shouldShowButton() && (
        <button
          onClick={() => redirectApp(currentPathname)}
          type="button"
          data-testid="redirect-button"
        >
          {buttonName}
        </button>
      )}
    </div>
  );
}
