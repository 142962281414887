export const BUTTON_EVENTS = {
  CLICK_SEND_DOCUMENTATION: {
    buttonNames: [
      "Complete registration",
      "Completar cadastro",
      "Registro completo",
    ],
    eventName: "documentation_sending_click",
  },
    CLICK_NEW_ATTEMPT_GENERIC_ERROR: {
    buttonNames: [
      "Go back",
      "Voltar",
      "Vuelve",
    ],
    eventName: "questionnaire_error_new_attempt_click",
  },
};

export const QUESTIONNAIRE_SCREEN_EVENTS = {
  SHOW_SUCCESS_ANSWER_SCREEN: "questionnaire_validation_success_screen",
  SHOW_INCORRECT_ANSWERS_SCREEN: "questionnaire_incorrect_answers_screen",
  SELECT_QUESTION_ANSWER_SCREEN: "question_answered_click",
  GENERIC_ERROR_SCREEN : "questionnaire_error_screen"
};
