import { useCallback, useEffect } from 'react'

import Routes from './routes'

import './styles/global.scss'
import styles from './styles/app.module.scss'

import i18next from './i18n/init'
import { isWebview, postMessageToWebview } from './utils/webview'
import { MESSAGE_TYPE } from './interfaces/webview.interfaces'

function App() {
  const initNavigationWebview = useCallback(() => {
    if (!isWebview()) return;

    postMessageToWebview(
      {
        message_type: MESSAGE_TYPE.NAVIGATION,
        data: {
          isBackButtonEnabled: false
        }
      }
    )
  }, []);

  useEffect(() => {
    i18next.changeLanguage('pt');
    initNavigationWebview();
  }, [initNavigationWebview]);

  return (
    <div className={styles.wrapper}>
      <Routes />
    </div>

  );
}

export default App;
