import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../constants/routes.constants';

interface IProgress {
  interval?: number;
  maxProgress?: number;
  difference?: number;
}

export const useProgress = ({
  interval = 6000,
  maxProgress = 100,
  difference = 5,
}: IProgress) => {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === maxProgress) {
          navigate({
            pathname: ROUTES.TIMEOUT_ERROR,
            search: location.search,
          });
        }
        return Math.min(oldProgress + difference, maxProgress);
      });
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [difference, navigate, interval, location.search, maxProgress]);

  return progress;
};
