export const ROUTES = {
  HOME: '/',
  ALREADY_ANSWERED_ERROR: '/already-answered',
  QUESTIONNAIRE_SENDING_ERROR: '/questionnaire-sending-error',
  GENERIC_ERROR: '/error',
  TIMEOUT_ERROR: '/timeout-error',
  FINAL: '/final',
  BIKEITAU: 'bikeitau://',
  TEMBICI: 'tembici://',
  ECOBICI: 'ecobici://',
};

export const REDIRECT_DOCUMENT_VALIDATION_PATHS = [
  ROUTES.QUESTIONNAIRE_SENDING_ERROR,
  ROUTES.TIMEOUT_ERROR,
];

export const REDIRECT_MAP_PATHS = [
  ROUTES.ALREADY_ANSWERED_ERROR,
  ROUTES.FINAL,
  ROUTES.GENERIC_ERROR
];
