import { IQuestions } from "../../../../interfaces/questions.interfaces";
import styles from './styles.module.scss';

export interface ICurrentQuestion extends IQuestions {
  onClickAnswer: (choice: string) => void;
}
const CurrentQuestion: React.FC<ICurrentQuestion> = ({ enunciation, choices, onClickAnswer }) => {
  return (
    <div className={styles.currentQuestion}>
      <h3>{enunciation}</h3>
      {choices.map((choice) => (
        <button
          key={choice}
          onClick={() => onClickAnswer(choice)}
        >
          {choice}
        </button>
      ))}
    </div>
  )
}

export default CurrentQuestion;