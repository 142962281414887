import i18next from 'i18next';
import { ParsedUrlQuery } from 'querystring';
import { defaultLanguage, languages } from '../i18n/config';

/**
 * Retorna a string de texto traduzida
 * @param text JSON string a ser capturado em um dos arquivos translation.json
 */
export const translate = (text: string): string => {
  return i18next.t(text);
};

/**
 * O retorno do método é um objeto que pode ser de qualquer tipo formado pelos idiomas disponíveis, por isso é unknown
 * @param path Path da URL
 * @param query Query da URL contendo possiveis parametros
 */
export const getUrlLanguages = (path: string, query: ParsedUrlQuery): unknown => {
  // Remover os parâmetros de lng já existentes para caso o usuário selecione outro idioma
  if (path.indexOf('lng=') >= 0) {
    languages.forEach((l) => {
      path = path.replace('&lng=' + l.lang, '');
      path = path.replace('?lng=' + l.lang, '');
    });
  }

  // Adicionar os idiomas disponíveis para o componente
  const langs = {};
  languages.forEach((l) => {
    langs[l.lang] = path + (Object.keys(query).length > 1 ? '&' : '?') + 'lng=' + l.lang;
  });

  return langs;
};

export const getAcceptLanguage = (): string => {
  try {
    const lang = languages.find((x) => x.lang === i18next.language)?.acceptLanguage;
    return !lang ? defaultLanguage : lang;
  } catch (error) {
    return defaultLanguage;
  }
};