import { BrowserRouter, Route, Routes as ReactRouterDomRoutes } from 'react-router-dom';

import { ROUTES } from './constants/routes.constants';

import { translate } from './utils/translate';
import { Slug } from './pages/Slug';
import { Questions } from './pages/Questions';

export default function Routes() {
  return (
    <BrowserRouter>
      <ReactRouterDomRoutes>
        <Route path={ROUTES.HOME} element={<Questions />} />

        <Route path={ROUTES.FINAL} element={
          <Slug
            title={translate('FINAL.TITLE')}
            subtitle={translate('FINAL.SUBTITLE')}
            buttonName={translate('FINAL.BUTTON')}
            image={translate('FINAL.IMAGE_ITAU_E_ECOBICI')}
          />
        } />

        <Route path={ROUTES.GENERIC_ERROR} element={
          <Slug
            title={translate('GENERIC_ERROR.TITLE')}
            subtitle={translate('GENERIC_ERROR.SUBTITLE')}
            buttonName={translate('GENERIC_ERROR.BUTTON')}
            image={translate('GENERIC_ERROR.IMAGE')}
          />
        } />

        <Route path={ROUTES.TIMEOUT_ERROR} element={
          <Slug
            title={translate('TIMEOUT_ERROR.TITLE')}
            subtitle={translate('TIMEOUT_ERROR.SUBTITLE')}
            buttonName={translate('TIMEOUT_ERROR.BUTTON')}
            image={translate('TIMEOUT_ERROR.IMAGE')}
          />
        } />

        <Route path={ROUTES.ALREADY_ANSWERED_ERROR} element={
          <Slug
            title={translate('ALREADY_ANSWERED_ERROR.TITLE')}
            subtitle={translate('ALREADY_ANSWERED_ERROR.SUBTITLE')}
            buttonName={translate('ALREADY_ANSWERED_ERROR.BUTTON')}
            image={translate('ALREADY_ANSWERED_ERROR.IMAGE')}
          />
        } />

        <Route path={ROUTES.QUESTIONNAIRE_SENDING_ERROR} element={
          <Slug
            title={translate('QUESTIONNAIRE_SENDING_ERROR.TITLE')}
            subtitle={translate('QUESTIONNAIRE_SENDING_ERROR.SUBTITLE')}
            buttonName={translate('QUESTIONNAIRE_SENDING_ERROR.BUTTON')}
            image={translate('QUESTIONNAIRE_SENDING_ERROR.IMAGE_ITAU_E_ECOBICI')}
          />
        } />

      </ReactRouterDomRoutes>
    </BrowserRouter>
  );
}
