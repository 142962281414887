export const QUESTIONS = {
  ENUNCIATION: 'Enunciado',
  OPTION1: 'Opção 1',
  OPTION2: 'Opção 2',
  OPTION3: 'Opção 3',
  OPTION4: 'Opção 4',
};

export const APPS = {
  BIKEITAU: 'bikeitau',
  ECOBICI: 'ecobici,',
  TEMBICI: 'tembici',
};
