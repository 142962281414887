import { useCallback, useEffect, useState } from "react";

import styles from "./styles.module.scss";
import { api } from "../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes.constants";
import { APPS, QUESTIONS } from "../../constants/index.constants";

import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import CurrentQuestion from "./components/CurrentQuestion";

import { useQuery } from "../../hooks/useQuery";
import { useProgress } from "../../hooks/useProgress";

import { translate } from "../../utils/translate";
import {
  IQuestions,
  IValidateQuestionsResponse,
} from "../../interfaces/questions.interfaces";
import { trackEventToWebView } from "../../interfaces/webview.interfaces";
import { QUESTIONNAIRE_SCREEN_EVENTS } from "../../constants/events.constants";

const useStyles = makeStyles({
  root: {
    width: "90%",
  },
  colorPrimary: {
    backgroundColor: "var(--white_dark)",
    color: "black",
  },
  barColorPrimary: {
    backgroundColor: "var(--orange)",
  },
  circularProgressColorPrimary: {
    color: "var(--orange)",
  },
  barColorSecondary: {
    backgroundColor: "var(--pink)",
  },
  circularProgressColorSecondary: {
    color: "var(--pink)",
  },
});

export function Questions() {
  const qs = useQuery();
  const ticketId = qs.get("ticketId");
  const app = qs.get("app");

  const [questions, setQuestions] = useState<IQuestions[]>([
    {
      enunciation: QUESTIONS.ENUNCIATION,
      choices: [
        QUESTIONS.OPTION1,
        QUESTIONS.OPTION2,
        QUESTIONS.OPTION3,
        QUESTIONS.OPTION4,
      ],
    },
  ]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<string[]>([]);
  const classes = useStyles();
  const progress = useProgress({
    interval: 6000,
    maxProgress: 100,
    difference: 5,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const getClasses = (initial, variant) =>
    app === APPS.TEMBICI ? variant : initial;

  const verifyErr = useCallback(
    function verifyErr(err, pathname) {
      if (err.response.status === 410) {
        navigate({
          pathname: ROUTES.ALREADY_ANSWERED_ERROR,
          search: location.search,
        });

        return;
      }
      navigate({
        pathname,
        search: location.search,
      });
    },
    [navigate, location.search]
  );

  const loadTicket = useCallback(
    async function loadTicket() {
      try {
        const { data } = await api.get(
          `/customers/questionnaire?ticket_id=${ticketId}`
        );
        setQuestions(data.result.questions);
      } catch (err) {
        trackEventToWebView(QUESTIONNAIRE_SCREEN_EVENTS.GENERIC_ERROR_SCREEN)
        verifyErr(err, ROUTES.GENERIC_ERROR);
      }
    },
    [ticketId, verifyErr]
  );
  useEffect(() => {
    loadTicket();
  }, [loadTicket]);

  const checkAnswers = useCallback(
    (validatedQuestions: IValidateQuestionsResponse) => {
      if (validatedQuestions.result === "VALID") {
        trackEventToWebView(
          QUESTIONNAIRE_SCREEN_EVENTS.SHOW_SUCCESS_ANSWER_SCREEN
        );

        navigate({ pathname: ROUTES.FINAL, search: location.search });
        return;
      }

      trackEventToWebView(
        QUESTIONNAIRE_SCREEN_EVENTS.SHOW_INCORRECT_ANSWERS_SCREEN
      );

      navigate({
        pathname: ROUTES.QUESTIONNAIRE_SENDING_ERROR,
        search: location.search,
      });
    },
    [navigate, location.search]
  );

  const callAPI = useCallback(
    async function callAPI() {
      if (questions.length !== answers.length) return;

      try {
        const { data } = await api.post<IValidateQuestionsResponse>(
          "/customers/questionnaire/answer",
          {
            ticket_id: ticketId,
            answers,
          }
        );
        checkAnswers(data);
      } catch (err) {
        trackEventToWebView(
          QUESTIONNAIRE_SCREEN_EVENTS.SHOW_INCORRECT_ANSWERS_SCREEN
        );
        verifyErr(err, ROUTES.QUESTIONNAIRE_SENDING_ERROR);
      }
    },
    [answers, questions.length, ticketId, verifyErr, checkAnswers]
  );

  useEffect(() => {
    callAPI();
  }, [callAPI]);

  function handleAnswerOptionClick(choice: string) {
    trackEventToWebView(
      QUESTIONNAIRE_SCREEN_EVENTS.SELECT_QUESTION_ANSWER_SCREEN
    );

    setAnswers([...answers, choice]);

    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    }
  }

  if (questions[0].enunciation === QUESTIONS.ENUNCIATION) {
    const colorPrimary = getClasses(
      classes.circularProgressColorPrimary,
      classes.circularProgressColorSecondary
    );
    return (
      <div className={styles.circularProgress}>
        <CircularProgress classes={{ colorPrimary }} />
      </div>
    );
  }
  const questionClass = getClasses(
    styles.questionsBox,
    styles.questionsBoxTembici
  );
  const barColorPrimary = getClasses(
    classes.barColorPrimary,
    classes.barColorSecondary
  );

  return (
    <div className={styles.questionsContainer}>
      <div className={questionClass}>
        <div className={styles.topBar}>
          <div className={classes.root}>
            <LinearProgress
              classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary,
              }}
              variant="determinate"
              value={progress}
            />
          </div>
          <h6>{translate("QUESTIONS.SUBTITLE")}</h6>
        </div>
        <CurrentQuestion
          choices={questions[currentQuestion].choices}
          enunciation={questions[currentQuestion].enunciation}
          onClickAnswer={handleAnswerOptionClick}
        />
      </div>
    </div>
  );
}
