import { WEBVIEW } from "../constants/storage.constants";
import { ACCEPTED_DEVICE, IWebviewPostMessage } from "../interfaces/webview.interfaces";

/**
 * `postMessage` envia uma mensagem para o app, caso o usuário esteja acessando a aplicação por meio da webview dentro do app da Tembici.
 *
 *
 * Esta função faz uso da função `window.ReactNativeWebView.postMessage`, que só existe para os casos de acesso por meio de webview
 *
 *
 * Nota: caso o usuário esteja acessando a aplicação por meio do browser, seja ele em um desktop ou em um device, esta função não executa nenhuma ação
 *
 *
 * @see https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#postMessage
 */

const ReactNativePostMessage = (data: IWebviewPostMessage) => {
  if (typeof data !== 'object') {
    return window?.ReactNativeWebView?.postMessage(data);
  }
  window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
};

export const postMessageToWebview = (data: IWebviewPostMessage): void => {
  const origin = localStorage.getItem(WEBVIEW.ORIGIN_TYPE);
  if (origin !== ACCEPTED_DEVICE.REACT_NATIVE) return;

  ReactNativePostMessage(data);
}

export const isWebview = (): boolean => {
  let webviewOrigin;
  if (typeof window !== 'undefined') {
    webviewOrigin = localStorage.getItem(WEBVIEW.ORIGIN_TYPE);
  }

  return !!webviewOrigin;
};