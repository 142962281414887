import { postMessageToWebview } from "../utils/webview";
import { BUTTON_EVENTS } from "../constants/events.constants";

export interface WebViewProps {
  postMessage?: (data: any) => void;
}

export enum ACCEPTED_DEVICE {
  REACT_NATIVE = "react_native",
}

export enum MESSAGE_TYPE {
  SUCCESS = "success",
  ERROR = "error",
  TRACKER = "tracker",
  NAVIGATION = "navigation",
}

export enum EVENT_TYPE {
  BRAZE = "braze",
  FIREBASE = "firebase",
  APPSFLYER = "appsflyer",
}

const getEventFromButtonName = (buttonName: string): string | undefined => {
  for (const [, value] of Object.entries(BUTTON_EVENTS)) {
    if (value["buttonNames"].includes(buttonName)) {
      return value["eventName"];
    }
  }
};

export const trackEventButtonToWebView = (buttonName: string): void => {
  const eventName = getEventFromButtonName(buttonName);

  if(!eventName) return;
  trackEventToWebView(eventName);
};

export const trackEventToWebView = (eventName: string, eventType = EVENT_TYPE_ALL): void => {
  postMessageToWebview({
    message_type: MESSAGE_TYPE.TRACKER,
    data: {
      event_name: eventName,
      event_type: eventType,
    },
  });
};

export const EVENT_TYPE_ALL = [
  EVENT_TYPE.APPSFLYER,
  EVENT_TYPE.BRAZE,
  EVENT_TYPE.FIREBASE,
];

export const EVENT_TYPE_ALL_PRODUCT = [EVENT_TYPE.BRAZE, EVENT_TYPE.FIREBASE];

interface IPayloadEvent {
  [key: string]: unknown;
}

interface IErrorResponseData {
  title: string;
  code: string;
  detail: string;
}

interface ITrackerResponseData {
  event_name: string;
  properties?: IPayloadEvent;
  event_type: EVENT_TYPE[];
}

interface ISuccessResponseData {
  [key: string]: unknown;
}

interface INavigationData {
  isBackButtonEnabled: boolean;
  isCloseButtonEnabled?: boolean;
  pageTitle?: string;
}

interface IWebviewPostMessageSuccess {
  message_type: MESSAGE_TYPE.SUCCESS;
  data: ISuccessResponseData;
  go_to?: string;
}

interface IWebviewPostMessageError {
  message_type: MESSAGE_TYPE.ERROR;
  data: IErrorResponseData;
  go_to?: string;
}

export interface IWebviewPostMessageTracker {
  message_type: MESSAGE_TYPE.TRACKER;
  data: ITrackerResponseData;
  go_to?: string;
}

interface IWebviewPostMessageNavigation {
  message_type: MESSAGE_TYPE.NAVIGATION;
  data: INavigationData;
  go_to?: string;
}

export type IResponseData =
  | IErrorResponseData
  | ITrackerResponseData
  | ISuccessResponseData
  | INavigationData;
export type IWebviewPostMessage =
  | IWebviewPostMessageSuccess
  | IWebviewPostMessageError
  | IWebviewPostMessageTracker
  | IWebviewPostMessageNavigation;
